import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import furnitureStoreModule from '../furnitureStoreModule'
import router from '@/router'

export default function useFurnitureList() {
    const tableColumns = [
        { key: 'id', label: '#', sortable: true },
        { key: 'property',label:'Property', sortable: false },
        { key: 'code',label:'Code', sortable: false },
        { key: 'created_date',label:'Created Date', sortable: false },
        { key: 'actions' },
    ]
    const toast = useToast()
    const refListTable = ref(null);
    const perPage = ref(10)
    const perPageOptions = [10, 25, 50, 100]
    const currentPage = ref(1)
    const filterRoom = ref(null)
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const totalInventoryList = ref(0)
    const refInventoryListTable = ref(null)
    const FURNITURE_APP_STORE_MODULE_NAME = "app-furniture";
    const dataMeta = computed(() => {
        const localItemsCount = refInventoryListTable.value ? refInventoryListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalInventoryList.value,
        }
    })

    if (!store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
        store.registerModule(FURNITURE_APP_STORE_MODULE_NAME, furnitureStoreModule);
    }

    onUnmounted(() => {
        if (store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
            store.unregisterModule(FURNITURE_APP_STORE_MODULE_NAME);
        }
    });

    const refetchData = () => {
        refInventoryListTable.value.refresh()
    }

    const fetchData = (ctx, callback) => {
        const offset = perPage.value*(currentPage.value-1);

        store
            .dispatch('app-furniture/getInventoryListData', {
                perpage: perPage.value,
                offset: offset,
                order_by: 'id DESC',
                // keywords: filterRoomType.value
            })
            .then((response) => {
                const inventories = response.data.data
                totalInventoryList.value = response.data.total
                callback(inventories)
            });
    }

    const editData = (data) => {
        router.push({ 
            name: 'inventory-update',
            query: { propertyId: data.propertyId, inventoryId: data.inventoryId },
        });
    }

    const deleteData = (id) => {
        store.dispatch('app-furniture/deleteInventoryListData', id)
            .then(response=>{
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Delete Success",
                        icon: 'AlertTriangleIcon',
                        variant: 'success',
                    },
                })

                refetchData();
            }).catch(()=>{
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error Delete Blog Category",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    return {
        tableColumns,
        toast,
        refListTable,
        perPage,
        perPageOptions,
        filterRoom,
        fetchData,
        sortBy,
        isSortDirDesc,
        dataMeta,
        refInventoryListTable,
        currentPage,
        totalInventoryList,
        editData,
        deleteData,
        refetchData,
    }
}